@use "../../res/styles/variables.scss" as *;
.merchant-body {
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  .add-form {
    h2 {
      margin-bottom: 0.5em;
      font-weight: 300;
    }
  }
  &__nav-bar {
    position: relative;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: $boxShadow;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    .animated-line {
      width: 25%;
      padding: 1px;
      background-color: $color;
      position: absolute;
      bottom: 0;
      left: 0%;
      transition: all ease 0.5s;
    }
    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 25%;
      gap: 5px;
      // border-bottom: solid 2px;
      padding: 5px;
      //font-size: 20px;
    }
  }
  &__main {
    padding: 10px;
  }
  .merchant-dashboard-body {
    display: flex;
    justify-content: center;
    gap: 50px;
    &__card {
      display: flex;
      color: #fff;
      flex-direction: column;
      justify-content: center;
      background-image: linear-gradient(
        60deg,
        $color,
        adjust-color($color: $color, $alpha: -0.3),
        adjust-color($color: $color, $alpha: -0.5),
        $color
      );
      padding: 10px;
      box-shadow: $boxShadow;
      width: 10em;
      border-radius: 10px;

      h4 {
        border-bottom: 2px solid #ddd;
      }
      .info-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 15px 0;
      }
      p {
        font-size: 14px;
        &:last-of-type {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: auto;
    margin: 1em 0;
    list-style: none;
    gap: 2em;
    button {
      padding: 5px;
      border-radius: 5px 5px 0 0;
      border: 0;
      border-bottom: solid 2px transparent;
      transition: all ease 0.5s;
      flex: 1;
      width: 7em;
      cursor: pointer;
      font-weight: bold;
      position: relative;

      &:hover,
      &.active {
        border-bottom: solid 2px $color;
      }
    }
  }
  .table-con {
    width: 100%;
    overflow: auto;
  }
  .merchant-order-body {
    table {
      width: 60%;
      margin: 0 auto;
      button {
        padding: 2px;
        border-radius: 5px;
        cursor: pointer;
        background: darkred;
        color: #fff;
        font-size: small;
      }
    }
  }
  .merchant-products-body {
    position: relative;
    min-height: 80vh;
    table {
      width: 100%;
      white-space: nowrap;
      border-collapse: collapse;
      thead {
        box-shadow: $boxShadow;
        background: $color;

        tr {
          th {
            padding: 10px;
            font-size: small;
            color: #fff;
            font-weight: bolder;
          }
        }
      }
      tbody {
        text-align: center;
        tr {
          cursor: pointer;
        }
        td {
          font-size: small;
          padding: 10px;
          border: solid 1px rgba(128, 128, 128, 0.1);
        }
      }
    }
    &__edit-form {
      overflow: auto;
      position: absolute;
      background-color: #fff;
      width: 50%;
      z-index: 1;
      padding: 10px;
      margin: 0 auto;
      height: 100%;
      left: 0;
      right: 0;
      border-radius: 5px;
      box-shadow: $boxShadow;

      .close {
        position: absolute;
        right: 10px;
        top: 10px;
        color: red;

        cursor: pointer;
      }
      &__body {
        margin-top: 1em;
        label {
          font-size: small;
        }
        &__input-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          &__input-box {
            display: flex;
            flex-direction: column;
            width: 45%;
            input {
              padding: 5px;
              margin-top: 5px;
              border-radius: 5px;
              font-size: small;
            }
          }
        }
        textarea {
          padding: 10px;
          width: 100%;
          resize: vertical;
          font-size: 12px;
        }
      }
      button {
        display: block;
        margin: 0 auto;
        padding: 5px 10px;
        margin-top: 2em;
        background: $color;
        color: #fff;
        border-radius: 5px;
      }
      .image-row {
        margin: 2em 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        .image-box {
          position: relative;
          width: 10em;
          display: inherit;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 10em;
          background: #fff;
          border: solid silver 1px;
          gap: 2px;
          padding: 5px;
          border-radius: 5px;
          img {
            max-width: 100%;
            max-height: 90%;
            margin: auto;
            opacity: 1;
          }
          svg {
            position: absolute;
            top: -10px;
            right: -10px;
            z-index: 5;
            cursor: pointer;
            background: #fff;
          }

          &.loading {
            overflow: hidden;
            background-color: $color;
            button,
            small {
              opacity: 0;
            }
            svg {
              display: none;
            }
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              inset: 0;
              transform: translateX(-100%);
              background: linear-gradient(to right, $color, #ddd);
              animation: loading 1s infinite;
            }
            img {
              opacity: 0;
              button,
              small {
                opacity: 0;
              }
            }
          }
          small {
            display: block;
            font-size: 10px;
          }
          button {
            padding: 2px;
            font-size: 10px;
            margin: 0;
            cursor: pointer;
          }
          &.add {
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            svg {
              position: relative;
              top: auto;
              right: auto;
              max-height: 65%;
              background: transparent;
            }
            input {
              position: absolute;
              inset: 0;
              opacity: 0;
              z-index: 10;
              cursor: pointer;
              background: #000;
            }
          }
          &.new {
            box-shadow: $boxShadow;
            img {
              max-height: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      box-shadow: $boxShadow;
      background: $color;

      tr {
        th {
          padding: 10px;
          font-size: small;
          color: #fff;
          font-weight: bolder;
        }
      }
    }
    tbody {
      text-align: center;
      tr {
        cursor: pointer;
      }
      td {
        font-size: small;

        padding: 10px;
        border: solid 1px rgba(128, 128, 128, 0.1);
        div {
          display: flex;
          gap: 10px;
          justify-content: center;
          button {
            padding: 7.5px;
            font-size: small;
            &.red {
              background: green;
            }
          }
        }
      }
    }
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
@media screen and (max-width: 950px) {
  .merchant-body {
    &__nav-bar {
      width: 80%;
    }
    .merchant-products-body {
      &__edit-form {
        width: 80%;
      }
    }
  }
  .add-form {
    width: 90%;
  }
}
@media screen and (max-width: 630px) {
  .merchant-body {
    &__nav-bar {
      width: 100%;
      overflow: auto;
    }
    .merchant-products-body {
      &__edit-form {
        width: 100%;
        &__body {
          &__input-row {
            flex-direction: column;
            width: 100%;
            &__input-box {
              width: 100%;
              input {
                margin-bottom: 1em;
                font-size: large;
                border: solid 2px #ddd;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
  .add-form {
    width: 100% !important;
    padding: 3px;
    .input-con-wrap {
      flex-direction: column;
      .input-con {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
}
