$color: #075c5f;
$shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.034),
  -1px -1px 1px 1px rgba(0, 0, 0, 0.034);
.account_logo {
  background-color: $color;
  color: #ffffff;
  font-size: larger;
  font-weight: 600;
  padding: 0.3em;
  font-size: x-large;
  a,
  a:hover,
  a:visited {
    color: white;
    text-decoration: none;
  }
}
.account_parent {
  background-color: rgb(236, 240, 245);
}
.account_header {
  position: sticky;
  top: 0;
  padding: 0.5em;
  box-shadow: $shadow;
  background-color: #ffffff;
  font-weight: 600;
  text-align: center;

  align-items: center;
  display: flex;

  p {
    margin: 0 auto;
  }
}
.order_header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px;
  p {
    flex-grow: 1;
    text-align: center;
    font-size: large;
    padding: 0.5em 0;
    transition: all 0.2s linear;
  }
  .active {
    border-bottom: solid 1px $color;
  }
}
.order_card_con {
  padding: 0.5em;
}

.account_options_con {
  margin: 1em 0;
  background-color: #ffffff;
  text-align: center;

  p {
    padding: 1.5em 0;
  }
}

.logout_con {
  bottom: 0;
  text-align: center;
  background-color: #ffffff;
  // margin: 2em 0;
  padding: 1em;
  background-color: goldenrod;
  color: white;
}
.account_options_parent {
  position: relative;
}
.option_con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5em;
  & :first-child {
    margin-right: 0.2em;
  }

  svg {
    color: $color;
  }
}
.profile_parent {
  // background-color: #ffffff;
  padding: 5px;
  .edit_con {
    margin-bottom: 0.5em;
    text-align: right;
    svg {
      color: $color;
    }
  }
  .profile_bio {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.034),
      -2px -2px 1px 1px rgba(255, 255, 255, 0.034);
    //inset 5px 5px 10px rgba(0, 0, 0, 0.103),
    //inset -5px -5px 10px rgba(0, 0, 0, 0.103);
  }
  .profile_details_con {
    p {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4em;
      align-items: center;
      .key {
        font-size: small;
        width: 40%;
      }
      .value {
        color: #075c5f;
        font-weight: 600;
        width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:last-of-type {
        margin-bottom: 1em;
      }
    }
  }
}
.change_password {
  text-align: right;
}
