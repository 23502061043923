@use "../../../res/styles/variables.scss" as *;
.customer-con {
  width: 100%;
}
.customer-profile-con {
  width: 50%;
  margin: 0 auto;
  .customer-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    padding: 10px;
    box-shadow: $boxShadow;
    border-radius: 5px;
    p:last-of-type {
      font-weight: bold;
    }
    p:first-of-type {
      font-weight: lighter;
    }
  }
  .verified {
    background-color: $color;
    color: #fff;
  }
  .unverified {
    background-color: $heartColor;
    color: #fff;
  }
  .profile-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;

    button {
      transition: all 0.5s;
      position: relative;
      display: block;
      padding: 10px;
      background-color: #fff;
      box-shadow: $boxShadow;
      border: 0;
      z-index: 1;
      border-radius: 5px;
      cursor: pointer;
      &::before {
        transition: all 0.2s;
        position: absolute;
        border-radius: 5px;
        width: 100%;
        height: 0%;
        content: "";
        background-color: $color;
        top: 0%;
        right: 0;
        z-index: -1;
      }

      &:hover {
        color: #fff;
        font-weight: bold;
        scale: 1.1;
        &::before {
          height: 100%;
        }
      }
    }
  }
}
