@use "../../res/styles/variables.scss" as *;
.edit-product-con {
  width: 85%;
  margin: 0 auto;
  background-color: #f4f7fa;
  box-shadow: $boxShadow;
  border-radius: 10px;
  border: solid 2px #ffffff;
  padding: 16px;
  &__body {
    padding: 16px;
    button {
      //  display: block;
      padding: 5px 1em;
      background: $color;
      border-radius: 5px;
      color: #fff;
      margin-left: auto;
      width: 5em;
    }
    .detail-form {
      @include flex($alignItems: center, $justifyContent: space-between);
      // background: #000;
      margin-bottom: 1em;
      gap: 10px;
      &__body {
        // flex: 1;
        width: 80%;
        //  background: #000;
        display: inherit;
        // justify-content: space-around;
        flex-direction: column;
        gap: 5px;
        input,
        textarea,
        select {
          flex: 1;
          padding: 5px;
          border-radius: 5px;
          border: solid 2px #ddd;
          font-size: 16px;
          //  width: 80%;
          &:focus {
            outline: solid 1px $color;
          }
          &:disabled {
            background-color: grey;
          }
        }
        small {
          font-size: 12px;
          opacity: 0.7;
        }
      }

      textarea {
        resize: none;
        padding: 10px;
      }
    }
    &__images {
      @include flex();
      width: 100%;
      overflow: auto;
      gap: 10px;

      &__image-card {
        position: relative;
        box-shadow: $boxShadow;
        border-radius: 10px;
        padding: 0.5em;
        width: 10em;
        height: 13em;
        background: #fff;
        display: inherit;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        //  overflow: hidden;

        gap: 10px;
        img,
        input {
          max-width: 100%;
          max-height: 100%;
        }
        p {
          width: 100%;
          font-size: 10px;
          //  background: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        svg {
          top: 5px;
          right: 5px;
          width: 1em;
          height: 1em;
          position: absolute;
          cursor: pointer;
          path {
            fill: red;
          }
        }
      }
    }
  }
}
