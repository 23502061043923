$starColor: gold;
$heartColor: #c70039;
$color: #075c5f; //#1fb5ad; // #075c5f//#16a085;
$backgroundColor: rgb(240, 240, 240);

$backgroundcolor: rgb(241, 239, 239);
$drawerColor: black;
$adminBgColor: #f1f2f7;
$cardBackgroundColor: #ffffff;
$boxShadow: 2px 2px 2px 1px rgba(221, 221, 221, 0.26),
  -1px -1px 2px 1px rgba(0, 0, 0, 0.11);
$transition: all ease-in-out 0.2s;
$boxShadowHover: 2px 2px 5px 5px rgba(221, 221, 221, 0.062),
  -1px -1px 5px 5px rgba(221, 221, 221, 0.1);
$navyColor: navy;
$marginTop: 18px;
$desColor: #f1f7fd6b;
$desTextColor: rgb(106, 160, 160);
$transition: all ease-in 0.3s;
@mixin flex(
  $justifyContent: flex-start,
  $alignItems: center,
  $flexDirection: row
) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
}
