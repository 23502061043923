@use "./variables.scss" as *;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@mixin login {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #3a3838;
}
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 5px;
  cursor: pointer;
}
$label_color: #fbfcc5;
@mixin flex-row {
  display: flex;
  justify-content: space-around;
  overflow: visible;
  align-items: center;
}
.blue_background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  min-height: 100%;
  z-index: -1;
  min-width: 100%;
  right: 0;
}
.parent {
  padding: 5px;
  color: #041368 !important;
}
.cart_logo {
  height: 2em;
  width: 2em;
}

.section_one {
  position: sticky;
  bottom: 0;
  // height: 33.3%;
  min-width: 100%;
  padding: 0.2em 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login_header {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-bottom: 1em;
}
.line,
.line2 {
  height: 1px;
  position: absolute;
  width: 20%;
  top: 50%;
  background-color: rgb(255, 255, 255);
  left: 5em;
}

.line2 {
  right: 5em !important;
  left: auto;
}

.section_two {
  margin-top: 1em;
  width: 100%;
}
.login_text {
  font-size: 1.5em;
  font-weight: bold;
  color: $color !important;
  a {
    color: #ffffff;
    text-decoration: none;
    :visited {
      color: red !important;
    }
  }
}
.input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  margin-bottom: 0em;
  select {
  }
}

.input,
select {
  width: 100%;
  font-size: larger;
  border: none;
  padding: 5px;
  transition: all linear 0.3s;
  border-radius: 5px;
  background-color: #fff;
  border: solid 2px #ddd;
}

.label {
  margin-bottom: 5px;
  //transition: all linear 0.3s;
  //transform: translateY(1.4em);
  // z-index: -1;
  font-size: medium;
}

label {
  color: rgb(101, 101, 104);
}
.form {
  // margin-top: 1em;
  padding: 0;
}

.input:focus {
  outline: solid 1px $color;
}

.login_btn {
  width: 100%;
  color: #ffffff;
  height: max-content;
  padding: 5px;
  border: none;
  border-radius: 5px;
  transition: all 0.5s linear;
  font-size: large;
  font-weight: bold;
  background-color: $color;
  cursor: pointer;
  p {
    padding: 0;
  }
  .login_loader {
    padding: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: dotted 5px #fff;
    animation: spin infinite linear 5s;
    transform: rotate(0deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login_btn:hover {
  filter: brightness(90%);
  // box-shadow: -1px -1px 5px 1px inset rgba(99, 98, 98, 0.514);
}
.slide__in__right {
  animation: slide_in_right 0.5s linear forwards;
}

.slide__out__right {
  animation: slide_out_right 0.5s linear forwards;
}

.slide__out__left {
  animation: slide_out_left 0.5s linear forwards;
}

.slide__in__left {
  animation: slide_in_left 0.5s linear forwards;
}

.form_con_parent {
  display: flex;
  //overflow-x: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  h2 {
    color: $color;
  }
}

.form_con {
  min-width: 100%;
}

@keyframes slide_out_left {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide_in_left {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide_out_right {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide_in_right {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.sex_con {
  @include flex-row;
}
.input_sex_con {
  @include flex-row();
}
input[type="checkbox"] {
  margin-right: 0.7em;
}
.move {
  animation: move 1s ease-in-out forwards;
}
@keyframes move {
  to {
    transform: scale(1.2, 1.2);
  }
}
.move_second {
  animation: move_second 2s ease-in-out forwards;
}
@keyframes move_second {
  to {
    transform: scale(1.2, 1.2);
  }
}
.unmove {
  animation: unmove 1s ease-in-out forwards;
}
.unmove__second {
  animation: unmove_second 2s ease-in-out forwards;
}
@keyframes unmove {
  from {
    transform: scale(1.2, 1.2);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes unmove_second {
  from {
    transform: scale(1.2, 1.2);
  }
  to {
    transform: scale(1, 1);
  }
}
.forgot_password {
  @include flex-row();
  justify-content: space-between;
  margin-top: 2em;
  a {
    font-size: small;
    color: $color;
    text-decoration: none;
  }
}
button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_icon {
  height: 3em;
  position: absolute;
  right: 0.5em;
  width: 3em;
  margin-left: auto;
}
button > p {
  color: #ffffff;
  padding: 0.5em 1em;
}
@media screen and (min-width: 750px) {
  .section_two {
    width: 50%;
    margin: 1em auto;
  }
}
